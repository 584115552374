<template>
    <div class="page">
        <page-header></page-header>
        <div class="page-peiple">
            <div class="page-peiple-left">
                <div class="title-text" style="margin-top: 10px">
                    <div>
                        <i class="iconfont icon-shoucang"></i>
                        <span>{{$t('newState.name')}}</span>
                    </div>
                    <div @click="goMyState" style="background:#f00;padding:6px 15px 8px 15px;border-radius:4px;font-size:12px;color:#fff;font-weight:400;margin-right:20px;cursor:pointer">{{$t('myState.title_text')}}</div>
                </div>
                <div class="love-list-item" v-for="(item,index) in stateList" :key="index">
                    <div @click="goUserXQ(item.userId)">
                        <img :src="$isApi.http + item.header" alt="">
                    </div>
                    <div>
                        <div class="love-list-name">
                            <span>{{item.nickName}}</span>
                            <i v-if="item.sex == 'women'" style="color:#FE9ED4" class="iconfont icon-xingbienv1"></i>
                            <i v-else style="color:#6798FF" class="iconfont icon-xingbienan1"></i>
                        </div>
                        <div class="live-list-di">
                            <div>
                                <span v-if="item.age">{{item.age}}{{$t('age.name')}}</span>
                                <span v-if="item.area">{{item.area}}</span>
                            </div>
<!--                            <div>{{$isPulic.timestampToTime(item.createDate)}}</div>-->
                        </div>
                        <div class="live-list-say">{{item.content}}</div>
                        <div class="live-list-imgs" v-if="item.fileUrl instanceof Array">
                            <el-image 
                                fit="contain"
                                class="state-center-imgss"
                                v-for="(img,index) in item.fileUrl" 
                                :key="index" 
                                :src="img"
                                :preview-src-list="item.fileUrl">
                            </el-image>
                        </div>
                        <div class="state-center-imgs" v-else-if="item.fileUrl.IsPicture('.jpg') || item.fileUrl.IsPicture('.png') || item.fileUrl.IsPicture('.gif') || item.fileUrl.IsPicture('.jpeg')">
                            <el-image 
                                fit="contain"
                                class="state-center-imgss"
                                :src="item.fileUrl"
                                :preview-src-list="[item.fileUrl]">
                            </el-image>
                        </div>
                        <div v-else-if="item.type == 'video'">
                            <div @click="videoChange" v-if="sendForm.level == 0" class="iconfont icon-icon_play video-icon"></div>
                            <video 
                                v-else
                                :src="$isApi.http + item.fileUrl"
                                class="video-avatar"
                                controls="controls" />
                        </div>
                    </div>
                </div>
                <el-pagination
                    class="page-size-number"
                    background
                    @current-change="currentChange"
                    :page-size="pageSize"
                    :current-page="pageNo"
                    layout="prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
            <div class="page-peiple-right">
                <love-gift></love-gift>
                <love-wall></love-wall>
            </div>
        </div>
        <page-fixed></page-fixed>
        <page-bottom></page-bottom>
        <transition name="el-zoom-in-top">
            <user-form-list v-if="$store.state.sayShow"></user-form-list>
        </transition>
        <transition name="el-zoom-in-top">
            <gift-list v-if="$store.state.giftShow"></gift-list>
        </transition>
        <transition name="el-zoom-in-top">
            <pay v-if="$store.state.giftShow"></pay>
        </transition>
        <transition name="el-zoom-in-top">
            <pay v-if="$store.state.payShow"></pay>
        </transition>
        <transition name="el-zoom-in-top">
            <upgrade v-if="$store.state.upgradeShow"></upgrade>
        </transition>
    </div>
</template>

<script>
import PageHeader from '../../components/pageHeader.vue'
import PageBottom from '../../components/pageBottom.vue'
import PageFixed from '../../components/pageFixed.vue'
import GirlList from '../../components/girlData/girlList.vue'
import userFormList from '../../components/say.vue'
import LoveGift from '../../components/home/loveGift.vue'
import LoveWall from '../../components/home/loveWall.vue'
import GiftList from '../../components/gift.vue'
import Pay from '../../components/pay.vue'
import Upgrade from '../../components/upgrade.vue'
export default {
    components: {GirlList,PageHeader,PageBottom,PageFixed,userFormList,LoveWall,LoveGift,GiftList,Pay,Upgrade},
    data(){
        return{
            stateList: [],
            pageSize: 20,
            pageNo: 1,
            total: 1,
            sendForm: {}
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))
        this.wallLi(this.pageNo,this.pageSize)
    },
    methods: {
        videoChange(){
            this.$confirm(this.$i18n.t('login.shenVipState1'), this.$i18n.t('giftComponents.reminder'), {
                confirmButtonText: this.$i18n.t('userLeft.success'),
                cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                type: 'warning'
            }).then(() => {
                this.$store.state.upgradeShow = true
            })
        },
        goMyState(){
            this.$router.push({
                path: "/page/user/state"
            })
        },
        wallLi(pageNo,pageSize){
            this.$isAxios.axiosGet(this.$isApi.showAllDynamics,{pageNo:pageNo,pageSize:pageSize},(res)=>{
                if(res.data.result != null){
                    res.data.result.forEach( item => {
                        if(item.fileUrl){
                            if(item.fileUrl.indexOf(",") != -1){
                                item.fileUrl = item.fileUrl.split( ',' )
                            }
                        }
                    })
                    this.total = res.data.total
                    this.stateList = res.data.result
                }
            })
        },
        goUserXQ(id){
            sessionStorage['userId'] = id
            if(id == this.sendForm.userId){
                this.$router.push({
                    path: "/page/userXQ",
                })
            }else{
                this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:id,visitorId:this.sendForm.userId},(fang)=>{
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                })
            }
        },
        currentChange(e){
            this.wallLi(e,this.pageSize)
        }
    }
}
</script>

<style scoped>
.video-icon{
    width: 200px;
    height: 140px;
    background: #000;
    text-align: center;
    line-height: 140px;
    color: #fff;
    font-size: 24px;
    margin-top: 14px;
    cursor: pointer;
}
.love-wall-item{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.love-list-item{
    padding: 20px 20px 20px 0;
}
.love-wall-item:last-child{
    margin-bottom: 20px;
}
.love-wall-item>img{
    display: block;
    width: 60px;
    height: 60px;
    background: #ccc;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover
}
.love-wall-item>div{
    width: calc( 100% - 80px );
    height: max-content;
}
</style>